import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useCookies } from 'react-cookie';
import './style.scss'
import { navigate } from 'gatsby-link';


const ForgotPassword = () => {
  const [recoveryEmail, setRecoveryEmail] = useState("")
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false)
  const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [error, setError] = useState(false)
  const [validEmailError, setValidEmailError] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['user-jwt']);
  const user_token = cookies['user-jwt']

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const resetPassword = (e) => {
    e.preventDefault();
    setResetPasswordLoading(true)
    setError(false)
    setValidEmailError(false)

    if (validateEmail(recoveryEmail)) {
      axios.post(`${process.env.GATSBY_API_URL}/api/reset-password`,{email: recoveryEmail}, { headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }}).then((response) => {
        setResetPasswordLoading(false)
        setDisplayForgotPassword(false)
        setForgotPasswordSubmitted(true)
      }).catch((error) => {
        setResetPasswordLoading(false)
        setError(true)
      })
    } else {
      setError(true)
      setValidEmailError(true)
    }
  }

  useEffect(()=> {
    user_token  &&  navigate("/customer-portal/")
  }, [user_token])

  return (
    <main className="forgot-password-page main-content grid-section" role="main">
      <div className="login-wrapper">
        <form method="post">
          <div className="container">
            <div className="grid__item title one-whole text-center">
              <h1 className="heading">Forgot Password</h1>
            </div>
            <p className='subheader'>Forgot your password? Enter your email address below and we will send you instructions to reset it.</p>
            <div className="input-container">
              <label htmlFor="CustomerEmail" className="label">
                Email Address
              </label>
              <input type="email" name="email" value={recoveryEmail} onChange={(e) => setRecoveryEmail(e.target.value)} id="CustomerEmail" className="input-full" placeholder="Enter your email address" autoCorrect="off" autoCapitalize="off" autoFocus="" />

              <button disabled={recoveryEmail === ''} className='login-button reset-password-button' onClick={(e) =>  resetPassword(e)}>
                Send Reset Password Email
              </button>

              {validEmailError &&
                <h3 className='text-center mt-5'>Please input a valid email</h3>
              }

              {forgotPasswordSubmitted &&
                <h3 className='text-center mt-5'>Forgot Password Email Sent</h3>
              }
            </div>
          </div>
        </form>
      </div>
    </main >
  )
}

export default ForgotPassword
